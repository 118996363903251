import React, { useEffect, useRef, useState } from "react";
import "./appBlog.scss";
import Card from "./Card";
import { TableOfContents } from "./TableOfContents";
import { Share } from "./Share";

const SideBlog = (props) => {
  const { link, liveMediaImg,ksLiveUtm } = props;
  const [showToc, setShowToc] = useState(false);
  const [showRelatedContent, setshowRelatedContent] = useState(false);
  const [showKsLiveCard, setshowKsLiveCard] = useState(false);

  var firstFold = null;
  var secondFold = null;

  const openKslive = () => {
    window.open(ksLiveUtm, "_top",);
  };

  try {
    firstFold = (props.toc[0]?.url).split("#")[1];
  } catch {

  }

  try {
    secondFold = (props.toc[1]?.url).split("#")[1];
  } catch {

  }

  var thirdFold = null;
  var secondFoldScrollUp = null;
  var thirdFoldLength = Math.floor(props.toc.length/2);
  var thirdScrollUp = null;
  
  try {
    secondFoldScrollUp = (props.toc[thirdFoldLength-1]?.url).split("#")[1];
    thirdScrollUp = (props.toc[props.toc.length-1]?.url).split("#")[1];
  } catch {

  }

  try {
    thirdFold = (props.toc[thirdFoldLength]?.url).split("#")[1];
  } catch {

  }


  const observer = useRef();

  useEffect(() => {
    const topMargin = "-180px";

    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const intersecting = entry.isIntersecting;

          if (intersecting) {
            if (entry.target.id === "main-blog-page-header" || entry.target.id === firstFold) {
              setShowToc(false);
              setshowRelatedContent(false);
              setshowKsLiveCard(false);
            } else if (entry.target.id === secondFold || entry.target.id === secondFoldScrollUp) {
              setShowToc(true);
              setshowRelatedContent(true);
              setshowKsLiveCard(false);
            } else if (entry.target.id === thirdFold || entry.target.id === thirdScrollUp) {
              setShowToc(true);
              setshowRelatedContent(false);
              setshowKsLiveCard(true);
            } else if(entry.target.id === 'blog-footer'){
              setshowRelatedContent(false);
              setshowKsLiveCard(false);
            }
          }
        });
      },
      {
        rootMargin: topMargin,
      }
    );

    observer.current.observe(document.getElementById("main-blog-page-header"));
    observer.current.observe(document.getElementById('blog-footer'));

    if (firstFold) {
      observer.current.observe(document.getElementById(firstFold));
    }

    if (secondFold) {
      observer.current.observe(document.getElementById(secondFold));
    }

    if (secondFoldScrollUp) {
      observer.current.observe(document.getElementById(secondFoldScrollUp));
    }

    if (thirdScrollUp) {
      observer.current.observe(document.getElementById(thirdScrollUp));
    }

    if (thirdFold) {
      observer.current.observe(document.getElementById(thirdFold));
    }

    // Clean-up
    return () => observer.current.disconnect();
  }, []);

  return (
    <div className="blog-layout-container">
      {/* <Header /> */}

      <div className="blog-layout-wrapper container-max">
        {!props.hideSidebar && (
          <div className="sidebar2">
            {showToc && (
              <div className="cover mobile-none" id="sidebarHeader">
                <TableOfContents toc={props.toc} />
                <Share link={link} />
              </div>
            )}
          </div>
        )}
        <div
          className={
            "blog-content " + (props.hideSidebar ? "blog-content-center" : "")
          }
        >
          {props.children}
        </div>
        <div className="sidebar">
          {showRelatedContent && (
            <div className="cover" id="sidebar">
              <div className="header">Related courses</div>

              <Card
                title="Marketing Launchpad"
                about="This is 16 week online full-time program for which we select, train and invest in high potential graduates to help them start a fantastic career in marketing. The best part? You pay ONLY when you land a job with a minimum CTC of INR 4.5 lakhs."
                trademark="1800+ students have secured a marketing job without an MBA."
                link={props.link}
                list={[
                  "16 weeks, online, full-time program",
                  " Get hired in performance marketing, content, social media, and more",
                  "Highest CTC: ₹9.2 LPA",
                  "World’s largest agencies hire from us again and again",
                  "94% placement rate",
                ]}
              />
            </div>
          )}
          {/* <Sidebar3 /> */}
          {showKsLiveCard && (
            <div className="sideBarUpcommingEvent mobile-none" onClick={openKslive}>
              {liveMediaImg && <img
                src={`${liveMediaImg}`}
                alt="ks-live"
                placeholder="none"
                className="ks-live-banner-sidebar"
              />}
            </div>
          )}
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default SideBlog;
